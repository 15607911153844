import React from 'react'
import java from '../../assets/images/java.png'
import springimg from '../../assets/images/SpringBoot.png'
import flutterimg from '../../assets/images/flutter.png'
import mysql from '../../assets/images/MySQL.png'
import postgre from '../../assets/images/postgre.png'
import firebase from '../../assets/images/firebase.png'
import reactjs from '../../assets/images/React.png'
import reactnativejs from '../../assets/images/Reactlogo.png'
import angular from '../../assets/images/angularjs.png'
import node from '../../assets/images/nodejs.png'
import android from '../../assets/images/android.png'
import ios from '../../assets/images/ios.png'
import aws from '../../assets/images/aws.png'
import gcp from '../../assets/images/gcp.png'
import IconExperties from './IconExperties'


export default function TechExpertise() {
    return (
        <>
            <div className='flex justify-center md:justify-start md:mx-24 mb-24'>
                <h2 className='text-white text-4xl sm:text-5xl md:text-6xl text-center md:text-left pt-14 pb-4 w-max font-extrabold'>
                    Technology <br />
                    <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>
                        Expertise
                    </span>
                </h2>
            </div>


            <div className='w-full py-24 px-10 md:px-24 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5  gap-5 mb-20 bg-slate-800 '>
                <IconExperties iconImage={java} title={"Java"} />
                <IconExperties iconImage={springimg} title={"Spring Boot"} />
                <IconExperties iconImage={mysql} title={"MySQL"} />
                <IconExperties iconImage={postgre} title={"PostgreSQL"} />
                <IconExperties iconImage={firebase} title={"Firebase"} />
                <IconExperties iconImage={flutterimg} title={"Flutter"} />
                <IconExperties iconImage={reactnativejs} title={"ReactJS"} />
                <IconExperties iconImage={reactjs} title={"React Native"} />
                <IconExperties iconImage={angular} title={"Angular"} />
                <IconExperties iconImage={node} title={"NodeJS"} />
                <IconExperties iconImage={android} title={"Android"} />
                <IconExperties iconImage={ios} title={"iOS"} />
                <IconExperties iconImage={aws} title={"AWS"} />
                <IconExperties iconImage={gcp} title={"GCP"} />
            </div>


            {/* <div className='flex flex-col md:flex-row mt-20 lg:p-20 p-10 md:space-x-14 '>
                <div className='flex-auto md:w-32 mb-10 md:mb-0 p-10 border-blue-300 border-2 rounded-3xl'>
                    <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 text-3xl'>Discovery and exploration</h1>
                    <p className='text-white pt-8 text-xl'>
                        Workshops and ideation
                        In-depth user research
                        Market and data analysis
                        Product UX audits
                    </p>
                </div>
                <div className='flex-auto md:w-32 mb-10 md:mb-0  p-10 border-blue-500 border-2 rounded-3xl'>
                    <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 text-3xl'>UI/UX and product design</h1>
                    <p className='text-white pt-8 text-xl'>User flows and prototypes
                        UI and interaction design
                        Scalable design systems
                        User testing</p>
                </div>
                <div className='flex-auto md:w-32 mb-10 md:mb-0  p-10 border-blue-300 border-2 rounded-3xl '>
                    <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 text-3xl'>Strategy and UX consultancy</h1>
                    <p className='text-white pt-8 text-xl'>Product vision
                        Roadmapping and planning
                        Team upskilling
                        Process optimisation</p>
                </div>

            </div> */}

        </>
    )
}
