import React from 'react'
import checkimg from '../../assets/images/check.png'
import img from '../../assets/images/bgpic2.jpg'
import bgimg from '../../assets/images/nobg.png'
import MyButton from '../common/MyButton'
import { Link } from 'react-router-dom'
import WeServeCards from './WeServeCards'



export default function HomePage() {
  return (
    <div>
      <div className='w-full h-[500px] lg:h-[700px] bg-no-repeat px-10 pt-10 md:pt-20 md:px-24' style={{ backgroundImage: `url(${bgimg})`, alt: "loading", backgroundPosition: 'bottom', backgroundSize: '100%' }}>
        <h1 className='text-white text-xl text-left font-thin pt-20 '>Empowering innovation through technology and creativity</h1>
        <h1 className='text-white text-4xl lg:text-5xl text-left font-bold uppercase'>
          <span style={{ textShadow: "1px 1px 1px black" }}> We build easy, fun & quality</span>
          <br />
          <span className='text-4xl lg:text-5xl w-max pb-5 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>
            products.</span>
        </h1>
        <Link to="/aboutus"><MyButton text={"Know More"} /></Link>
      </div>
      <div className='lg:flex'>
        <div className='px-10 sm:px-24 py-20 lg:w-1/2'>
          <h1 className='text-4xl sm:text-5xl lg:text-7xl w-max pb-5  font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Who We Are?</h1>
          <p className='pt-5 text-2xl sm:text-3xl text-gray-200'>We are a team of passionate technology experts and gaming enthusiasts, dedicated to delivering easy + quality software and immersive gaming experiences.</p>
        </div>
        <div className='px-10 sm:px-24 sm:py-20 lg:border-l-4 border-dashed border-blue-500 lg:w-1/2 md:text-right lg:text-left'>
          <h1 className='lg:text-white text-3xl text-transparent bg-clip-text bg-gradient-to-l from-blue-500 via-purple-600 to-pink-400  lg:font-thin pb-4'>
            Innovating with a simple goal
          </h1>
          <p className='pt-5 text-xl text-gray-400'>
            Our company was founded with a simple goal to make technology accessible and fun for everyone.
            We believe that software and gaming should be designed with the user in mind, and that's why we put our heart and soul into every project we take on.
            We are constantly pushing the boundaries of what's possible and looking for new ways to bring joy to our users.
          </p>
        </div>
      </div>

      <div className='flex flex-col px-10 sm:px-24 mt-16 lg:mt-4'>
        <h1 className='lg:self-end self-start pb-3 text-transparent bg-clip-text font-extrabold bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 text-4xl sm:text-5xl text-right pt-8 w-max float-right'>  We Serve</h1>
        <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-12">
          <WeServeCards title="Web Development" img={checkimg} />
          <WeServeCards title="Mobile App Development" img={checkimg} />
          <WeServeCards title="Game Development" img={checkimg} />
          <WeServeCards title="UI/UX Design" img={checkimg} />
          <WeServeCards title="AR/VR Application" img={checkimg} />
          <WeServeCards title="Animation & Films" img={checkimg} />
        </div>
        <div className='self-end'>
          <Link to="/services"><span className='text-zinc-400 text-lg font-extralight hover:font-bold hover:text-purple-500 transition-all duration-200'>more...</span></Link>
        </div>
      </div>
      <div className='mt-10' style={{ backgroundImage: `url(${img})`, backgroundSize: '100% 100%' }}>
        <div className='w-full py-36 h-full flex justify-center bg-gray-900 bg-opacity-80'>
          <div className='self-center'>
            <h1 className='text-4xl sm:text-7xl text-transparent text-center font-extrabold bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'> NEED A CONSULTATION? </h1>
            <h1 className='mt-2 text-white text-lg sm:text-xl text-center font-thin'>Drop us a line! We are here to answer your questions 24/7</h1>
            <div className='text-center'>
              <Link to="/contactus"><MyButton text="Contact us" /></Link>
            </div>
          </div>
        </div>
      </div>
    </div>






  )
}
