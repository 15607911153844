import React from 'react'

export default function CreditPersonSection({ img, name, designation, children, isImageInRight }) {
    return (

        <div className={`flex flex-col-reverse ${isImageInRight ? " md:flex-row-reverse" : "md:flex-row"}  md:pt-20 lg:px-24 px-10 pb-10`} >
            <div className={`flex-auto py-10 md:py-0 md:px-16 lg:px-24`}>
                <img className='rounded-2xl shadow-2xl shadow-black pointer-events-none opacity-50' alt='' src={img} />
            </div>
            <div className='flex-auto md:w-1/2 lg:w-max'>
                <h1 className='text-4xl md:text-5xl lg:text-6xl h-12 md:h-20 bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 bg-clip-text w-max text-transparent font-extrabold'>
                    {name}
                </h1>
                <h2 className='text-white text-xl lg:text-2xl font-bold'>{designation}</h2>
                <div className='mt-8 md:mt-12' >
                    {children}
                </div>
            </div>
        </div>
    )
}
