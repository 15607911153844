import React from 'react'

export default function WeServeCards({img, title}) {
    return (
        <div className='flex items-center p-10 bg-gray-800 rounded-lg hover:bg-gray-900 mb-8 shadow-sm transition-all duration-500 hover:shadow-pink-400 shadow-purple-800 '>
            <div className='w-8 h-8'><img src={img} alt='loading' /></div>
            <h1 className=' pl-2 text-white text-xl'>{title}</h1>
        </div>
    )
}
