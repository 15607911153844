import React from 'react'
import img from '../../assets/images/bgpic.jpg'
import team from '../../assets/images/team.jpg'

import ab2img from '../../assets/images/ab2.png'

import PraleeIntroVideo from './Video'
import OurValuesCard from './OurValuesCard'
import OurTeamIsOurPower from './OurTeam'
import { Link } from 'react-router-dom'
import PageHeader from '../common/PageHeader'

export default function AboutPage() {
  return (
    <div>

      <PageHeader img={img}>
        We believe that<br/>Technology should be accessible and enjoyable for everyone
      </PageHeader>
      <div className='mx-10 md:mx-24'>
        <h1 className='text-white text-4xl md:text-6xl text-center md:text-right pt-14 font-extrabold'>Our <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Values</span> </h1>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-20'>
          <OurValuesCard title="Simplicity" image={ab2img}>
            We believe that technology should be easy to use and understandable.<br />We always create super easy products.
          </OurValuesCard>
          <OurValuesCard title="Quality" image={ab2img}>
            We pay close attention to every detail and ensure that every aspect of our products is of the highest quality.
          </OurValuesCard>
          <OurValuesCard title="Passion" image={ab2img}>
            We are passionate about technology and gaming and pour our hearts into every project we take on.
          </OurValuesCard>
          <OurValuesCard title="Innovation" image={ab2img}>
            We are constantly seeking new and creative solutions to problems and set high standards for products.
          </OurValuesCard>
          <OurValuesCard title="Fun" image={ab2img}>
            We believe that technology and gaming can be both functional and fun, and we create products that are both usable and enjoyable.
          </OurValuesCard>
          <OurValuesCard title="Our People" image={ab2img}>
            We support the personal and professional growth of our people and develop their skills and advance their careers.
          </OurValuesCard>

        </div>
      </div>
      <h2 className='text-white text-4xl md:text-6xl text-center md:text-left  px-10 md:px-24'><span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>We Are</span> Pralee </h2>
      <div className='py-20'>
        <PraleeIntroVideo />
      </div>
      <div style={{ backgroundImage: `url(${team})`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
        <div className='h-max w-full bg-gray-900 px-10 md:px-24 py-20 bg-opacity-90'>
          <h2 className='text-white text-4xl md:text-6xl text-center md:text-center px-10 md:px-24'>Our Team Is Our <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Power</span> </h2>
          <OurTeamIsOurPower isLeft={true} >
            We believe that our team's talent and expertise are what sets us apart and drive our success.
          </OurTeamIsOurPower>
          <OurTeamIsOurPower isLeft={false} >
            We empower our team members to take ownership of their work and make decisions that drive our success.
          </OurTeamIsOurPower>
          <OurTeamIsOurPower isLeft={true} >
            We trust our team members to do their best work and support them in achieving their goals.
          </OurTeamIsOurPower>
          <OurTeamIsOurPower isLeft={false} >
            We invest in our team's skills and expertise, providing opportunities for them to grow and develop professionally.
          </OurTeamIsOurPower>
        </div>
      </div>

      <div className='flex flex-col items-center justify-center h-full py-40 px-5 bg-gradient-to-br from-purple-800 via-blue-700 to-sky-600'>
        <h1 className='text-white text-4xl text-center'>No matter what's your industry <br />We can help you to achieve your goals</h1>
        <h1 className='text-white text-xl text-center pt-10 pb-10'>
          We offer customization options that allow us to create solutions that are tailored to each customer's specific needs.
        </h1>
        <span className='text-white'><Link to='/services' className='border-2 rounded-lg  p-4 mr-4 hover:bg-gray-800 transition-all ease-in-out duration-500'>Explore Services</Link>
          <Link to={'/contactus'} className='border-2 rounded-lg p-4 hover:bg-gray-800 transition-all ease-in-out duration-150'>Get in touch</Link></span>
      </div>
    </div>
  )
}
