import React from 'react'

export default function TechIconWithText({iconImage, title}) {
    return (
        <div className='flex flex-col items-center h-full w-max text-white text-center'>
            <img className='h-24 w-24 p-4' src={iconImage} alt="loading" />
            <h6 className='text-center'>{title}</h6>
        </div>
    )
}
