import React from 'react'
import img from '../../assets/images/bgpic.jpg'
import TechExpertise from './TechExpertise'
import PageHeader from '../common/PageHeader'
import TechnologyCard from './TechnologyCard'

export default function TechnologyPage() {
  return (
    <div>
      <PageHeader img={img}>
        Our team of experts<br />Stays up-to-date with the latest advancements in the tech industry
      </PageHeader>
      <div className='flex justify-center md:justify-end mx-24'>
        <h2 className='text-white text-5xl md:text-6xl text-center md:text-right pt-14  pb-4 w-max font-extrabold'>
          <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Technology<br /></span>
          <span className=''>We Use</span> </h2>
      </div>
      <div>
        <div className='flex flex-col px-10 md:px-24 py-10 md:mt-5'>
          <h2 className='text-center text-2xl sm:text-3xl text-white font-extralight'>Our technology stack includes<br /> a range of tools and platforms that we use to build software</h2>

          <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 mt-10'>
            <TechnologyCard title={"Front-End Technology"}
              subtitle="Our front-end development team is always up-to-date with the latest front-end technologies and trends. We understand the importance of staying ahead in this constantly evolving field and strive to use the latest and most advanced technologies in our projects."
              items={[
                "HTML",
                "CSS",
                "JavaScript",
                "Bootstrap",
                "Tailwind",
                "ReactJS",
                "Angular",
                "React Native",
                "Flutter",
              ]}
            />
            <TechnologyCard title="Back-End Technology"
              subtitle="By using the latest back-end technologies, we can ensure that our applications are fast, secure, and scalable. We can provide real-time data processing, improve the reliability of data storage, and help users handle increasing amounts of traffic."
              items={[
                "Java",
                "Spring",
                "Spring Boot",
                "NodeJS",
                "Firebase",
              ]}
            />
            <TechnologyCard title="Database Technology"
              subtitle="Data is the backbone of any application, and it's essential to have a robust and efficient database solution in place. Our development team has extensive experience in working with a variety of database technologies."
              items={[
                "MySQL",
                "PostgreSQL",
                "SQLite",
                "Hive",
                "ObjectBox",
                "MongoDB",
              ]}
            />
          </div>

        </div>
      </div>
      <TechExpertise />
    </div>
  )
}
