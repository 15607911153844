import React from 'react'
import logo from '../assets/images/praleelogo.png'
import linkedinimg from '../assets/images/linkedin.png'
import youtube from '../assets/images/youtube.png'
import github from '../assets/images/github.png'
import { Link } from 'react-router-dom'



export default function Footer() {
  return (
    <div className='bg-gray-800 text-white'>
      <div className='flex md:flex-row flex-col px-10 pt-10 sm:px-24 sm:pt-24 pb-10'>
        <div className='flex-auto w-full md:w-1/4 sm:pr-10 sm:mr-10'>
          <a href="https://praleetechnology.com/" className='flex justify-center sm:justify-start'>
            <img src={logo} className="h-11" alt="pralee logo" />
          </a>
          <p className='pt-6 text-gray-400'>
            Pralee is a leading software and gaming company dedicated to providing
            innovative, easy and fun products.</p>
        </div>
        <div className='flex flex-grow flex-row'>
          <div className='flex-auto pt-10 md:pt-0'>
            <h1 className='text-xl'> Discover </h1>
            <ul className='text-gray-400 pt-10'>
              <li className='pb-4 hover:text-white'><Link to='services'> Services</Link></li>
              {/* <li className='pb-4 hover:text-white'><Link to='technology'> Technology</Link></li> */}
              <li className='pb-4 hover:text-white'><Link to='gaming'> Gaming</Link></li>

            </ul>
          </div>
          <div className='flex-auto pt-10 md:pt-0'>
            <h1 className='text-xl '>About</h1>
            <ul className='text-gray-400 pt-10'>
              <li className='pb-4 hover:text-white'><Link to='aboutus'> About Us</Link></li>
              <li className='pb-4 hover:text-white'><Link to='contactus'> Contact Us</Link></li>
              {/* <li className='pb-4 hover:text-white'><Link to='credits'> Credits</Link></li> */}
            </ul>
          </div>
        </div>
        <div className='flex-auto pt-10 md:pt-0'>
          <h1 className='text-xl'>Follow Us</h1>
          <div className='flex flex-row md:flex-col pt-4 md:pt-8'>
            <div className='flex items-center p-2'>
              <div className='w-5 h-5'><img src={linkedinimg} alt='loading' /></div>
              <a href='https://www.linkedin.com/company/praleetechnology/' target="_blank" rel="noreferrer" className='flex items-center text-gray-400 hover:text-white'> <h1 className='pl-2'><span className='hidden md:inline'>LinkedIn</span></h1></a>
            </div>
            <div className='p-2'>
              <a href='https://www.youtube.com/@praleetechnology' target="_blank" rel="noreferrer" className='flex items-center text-gray-400 hover:text-white'>
                <div className='w-5 h-5'><img src={youtube} alt='loading' /></div>
                <h1 className='pl-2'><span className='hidden md:inline'>Youtube</span></h1>
              </a>
            </div>
            <div className='p-2'>
              <a href='https://github.com/praleetechnology' target="_blank" rel="noreferrer" className='flex items-center text-gray-400 hover:text-white'>
                <div className='w-5 h-5'><img src={github} alt='loading' /></div>
                <h1 className='pl-2'><span className='hidden md:inline'>GitHub</span></h1>
              </a>
            </div>

          </div>
        </div>
      </div>
      <hr />
      <div className='text-slate-400 text-center p-5'>
        © 2023 Pralee Technology &bull; All Rights Reserved

      </div>
    </div>
  )
}
