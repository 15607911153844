
import blender from '../../assets/images/blender_logo.png'
import uevid from '../../assets/videos/ue.mp4'
import blendervid from '../../assets/videos/blendervid.mp4'
import unityvid from '../../assets/videos/unityvid.mp4'
import unity from '../../assets/images/unity2.png'
import unreal from '../../assets/images/unreal2.png'

export default function TechForGaming() {
    return (
        <>
            <h1 className='text-white px-5 sm:px-0 text-4xl sm:text-6xl text-center font-extrabold'>
                Tech For <br />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400">
                    Gaming & Animation
                </span>
            </h1>
            <div className='relative mt-24'>
                <video src={uevid} autoPlay loop muted />
                <div className='h-full w-full absolute top-0 px-10 sm:px-24'>
                    <div className='h-full flex flex-col items-start justify-center'>
                        <div className='flex flex-row items-center'>
                            <img className='h-14 md:h-28 self-end' src={unreal} alt='unreal engine' />
                            <div className=' ml-5'>
                                <h3 className='md:text-2xl text-white font-extralight p-0 m-0'>Empowering with </h3>
                                <h2 className='text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-extrabold text-white p-0 m-0'>Unreal Engine</h2>
                            </div>
                        </div>
                        <div className=' sm:pl-6'>
                            <h3 className='mt-4 sm:text-4xl md:text-5xl lg:text-6xl text-white font-extralight'>
                                The world’s most advanced 3D creation tool
                            </h3>
                            <h2 className='text-white text-xs sm:text-xl mt-3'>We use unreal to create games, ar/vr applications and cinematography.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='my-4 flex flex-col-reverse md:flex-row'>
                <div className='flex-1 p-10 md:p-24'>
                    <video className='w-full rounded-3xl shadow-lg shadow-black' src={blendervid} autoPlay loop muted controls />
                </div>
                <div className='flex-1 p-10 md:p-24'>
                    <img className='px-10' src={blender} alt='blender 3d' />
                    <h2 className='text-white text-center text-2xl font-bold md:text-4xl mt-4'>Freedom to create anything</h2>
                    <p className='text-center text-white text-xl mt-3'>We use blender to create models, animations, product films, game assets and composition.</p>
                </div>
            </div>
            {/* <div className='relative'>
                <video className='w-full' src={unityvid} autoPlay loop muted />
                <div className='h-full w-full absolute top-0 px-10 sm:px-24  bg-gray-900 bg-opacity-90'>
                    <div className='h-full flex flex-col items-start justify-center'>
                        <div className='flex flex-row items-center justify-center'>
                            <div className='p-10  md:p-24'>
                                <img className='px-10 md:px-0 mx-auto w-3/4 md:w-1/2' src={unity} alt='unity 3d' />
                                <h2 className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 h-max text-center text-2xl font-extrabold md:text-5xl lg:text-7xl mt-4 uppercase'>Bring Your Vision To Life</h2>
                                <h2 className='text-center text-white text-xs sm:text-xl mt-3'>We use unity to create 2d games & ar/vr applications.</h2>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}

        </>
    )

}