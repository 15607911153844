import React, { useState } from 'react'
import logo from '../assets/images/praleelogo.png'
import { RiCloseLine, RiMenu4Line } from 'react-icons/ri'
import Sidebar from './Sidebar'

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    return (
        <nav className="px-2 sm:px-6 py-2 backdrop-blur-3xl bg-opacity-70 bg-gray-800 w-full z-20 left-0 fixed top-0">
            <div className="container flex flex-wrap items-center justify-between mx-auto w-full">
                <span className='w-10'></span>
                <h1 className='sr-only'>Pralee Technology</h1>
                <a href="/" className="flex items-center">
                    <img src={logo} className="h-11" alt="pralee logo" />
                </a>
                <div className="flex intems-center justify-end transition-all duration-300">
                    <button onClick={() => setIsMenuOpen(!isMenuOpen)} type="button" className="inline-flex items-center p-2 text-sm rounded-lg text-gray-400 hover:bg-gray-700" aria-controls="navbar-sticky" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        {isMenuOpen ? <RiCloseLine size={24} /> : <RiMenu4Line size={24} />}
                    </button>
                </div>
            </div>
            <div className={`flex justify-end absolute w-max right-0 z-10 mt-2 transition-all duration-300 ease-in-out ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <Sidebar onMenuChange={setIsMenuOpen} />
            </div>
        </nav>
    )
}
