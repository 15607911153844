import React from 'react'

export default function TechnologyCard({ title, subtitle, items }) {
    return (
        <div className='p-10 rounded-3xl shadow-sm transition-all duration-500 hover:shadow-pink-400 shadow-purple-800 '>
            <h1 className='text-transparent bg-clip-text font-bold bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 text-3xl'>{title}</h1>
            <p className='text-white py-4 text-lg '>
                {subtitle}
            </p>
            <ul className=' space-y-3 text-white pt-5 text'>
                {
                    items?.map((item) => <span className='px-4 py-1 mx-2 rounded-full inline-block bg-purple-900 font-light shadow-sm shadow-black' key={item}>
                        {item}
                    </span>)
                }
            </ul>

        </div>
    )
}
