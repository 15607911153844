import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar({ onMenuChange }) {
   const closeMenu = (e) => {
      onMenuChange(false)
   }
   return (
      <aside className="w-80 h-screen bg-gray-800 backdrop-blur-2xl" aria-label="Sidebar">
         <div className="overflow-y-auto py-4 px-3">
            <ul className="space-y-2">
               <li>
                  <Link to="/" onClick={closeMenu} className="flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700">
                     <span className="ml-3">Home</span>
                  </Link>
               </li>

               <li>
                  <Link to="aboutus" onClick={closeMenu} className="flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700">
                     <span className="flex-1 ml-3 whitespace-nowrap">About Us</span>
                  </Link>
               </li>
               <li>
                  <Link to="services" onClick={closeMenu} className="flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700">
                     <span className="flex-1 ml-3 whitespace-nowrap">Services</span>
                  </Link>
               </li>
               <li>
                  <Link to="gaming" onClick={closeMenu} className="flex items-center p-2 text-base font-normal  rounded-lg text-white hover:bg-gray-700">
                     <span className="flex-1 ml-3 whitespace-nowrap">Gaming</span>
                  </Link>
               </li>

               <li>
                  <Link to="contactus" onClick={closeMenu} className="flex items-center p-2 text-base font-normal  rounded-lg text-white hover:bg-gray-700">
                     <span className="flex-1 ml-3 whitespace-nowrap">Contact Us</span>
                  </Link>
               </li>


            </ul>
         </div>
      </aside>

   )
}
