import React from 'react'
import img from '../../assets/images/bgpic.jpg'
import appimg from '../../assets/images/Mobileapp.jpg'
import blender from '../../assets/images/blenderlogo.png'
import gameimg from '../../assets/images/gamedev2.jpg'
import reactimg from '../../assets/images/React.png'
import springimg from '../../assets/images/SpringBoot.png'
import java from '../../assets/images/java.png'
import flutter from '../../assets/images/flutter.png'
import unreal from '../../assets/images/unreallogo.png'
import uiuximg from '../../assets/images/uiux.jpg'
import arvrimg from '../../assets/images/Arvr.jpg'
import a1 from '../../assets/images/1.png'
import a3 from '../../assets/images/3.png'
import a4 from '../../assets/images/4.png'
import a5 from '../../assets/images/5.png'
import a6 from '../../assets/images/6.png'
import animimg from '../../assets/images/Animation.jpg'
import customsoftwarebg from '../../assets/images/customsoft.jpg'
import lastimg from '../../assets/images/bgpic2.jpg'
import webdev from '../../assets/images/web.jpg'
import healthcare from '../../assets/images/healthcare.png'
import ServiceSection from './ServiceSection'
import TechIconWithText from './TechIconWithText'
import MyButton from '../common/MyButton'
import { Link } from 'react-router-dom'
import PageHeader from '../common/PageHeader'

export default function ServicesPage() {
  return (
    <div >

      <PageHeader img={img}>
        We offer <br /> A range of cutting-edge software and gaming solutions
      </PageHeader>
      <div className='flex justify-center md:justify-end mx-24 mb-32 md:mb-24'>
        <h2 className='text-white text-5xl md:text-6xl text-center md:text-right pt-14 font-extrabold pb-4 w-max border-white'> Our <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Services </span> </h2>
      </div>


      {/* ui/ux Design */}
      <ServiceSection title="UI/UX Design" img={uiuximg} isLeftTitle={true}>
        Our UI/UX design services are focused on creating user-centered experiences that are intuitive, elegant, and accessible.
        Our team will work with you to understand your business goals and create a design that meets those needs.
        We believe in designing with empathy and always put the needs of the user first.
        Our goal is to create products that are not only beautiful but also improve the overall user experience.
      </ServiceSection>

      {/* Web development */}
      <div className='h-32' />
      <ServiceSection title="Web Development" img={webdev} isLeftTitle={false}>
        We develop and maintains web apps, We work on latest technologies and provide solutions with best possible technology stack.
        We carry more than just good coding skills.
        Whether you need a simple brochure website or a complex e-commerce platform, we have the skills and experience needed to bring your ideas to life.
      </ServiceSection>



      {/* Mobile App Development */}
      <div className='h-32' />
      <ServiceSection title="Mobile App Development" img={appimg} isLeftTitle={true}>
        We develop applications for android and ios platforms. As mobile users are increasing day by day it is important to reach maximum people on their preffered devices.
        We use the latest technologies and development methodologies to ensure that your mobile app is both fast and secure.
      </ServiceSection>

      {/* Game Development */}
      <div className='h-32' />
      <ServiceSection title="Game Development" img={gameimg} isLeftTitle={false}>
        In game development, we take complete responsibility for creating a tailored product specific to your needs and within your budget. We will lead your project through all the stages, from game design to post-release support.
        In addition, our team can also scale your game development team with additional resources to enhance your game production capacities.
      </ServiceSection>

      {/* AR/VR */}
      <div className='h-32' />
      <ServiceSection title="AR & VR Development" img={arvrimg} isLeftTitle={true}>
        We believe in sharing advances in AR VR technology and its applications in industry for the benefit of everyone. We help in deploying AR and VR technology that ultimately
        delivers great Experiential-Learning and unbelievable Return-On-Investment (ROI) benefits to our clients.
      </ServiceSection>

      {/* Animation */}
      <div className='h-32' />
      <ServiceSection title="Animation" img={animimg} isLeftTitle={false}>
        Our animation services help bring your ideas to life through visually engaging and creative content. Our team of experts will work with you to understand your business goals and create animations that meet those needs. Our process includes research, conceptualization, design, development, and final delivery.
        We use the latest technologies and techniques to ensure that your animations are both engaging and impactful.</ServiceSection>

      <div className='mt-20 h-max ' style={{ backgroundImage: `url(${customsoftwarebg})`, backgroundAttachment: 'fixed', backgroundPosition: '100%' }}>
        <div className='flex flex-col py-36 sm:py-52 justify-center items-center bg-gray-900 bg-opacity-90'>
          <h2 className='text-4xl sm:text-8xl text-transparent text-center font-extrabold bg-clip-text bg-gradient-to-br from-blue-600 via-purple-700 to-pink-500'> We Build<br />Custom Software </h2>
          <h6 className='text-white text-lg sm:text-xl text-center font-thin mt-4'>Empower your business with pralee-made solutions</h6>
        </div>
      </div>


      <div className='flex justify-center md:justify-start mx-24 mb-24 '>
        <h2 className='text-white text-4xl sm:text-6xl text-center md:text-left pt-20 w-max'>
          <span className='text-transparent bg-clip-text bg-gradient-to-l from-blue-500 via-purple-600 to-pink-400'>
            Top Technology We Use
          </span>
        </h2>
      </div>
      <div className='flex flex-col md:flex-row md:items-center items-center md:justify-between justify-center gap-10'>
        <div className='flex-auto w-full md:w-1/2 grid grid-cols-3 gap-4 bg-slate-800 md:rounded-r-full md:border-r-2 border-dashed border-blue-400 md:p-32 p-10'>
          <TechIconWithText iconImage={java} title={"Java"} />
          <TechIconWithText iconImage={springimg} title={"Spring"} />
          <TechIconWithText iconImage={flutter} title={"Flutter"} />
          <TechIconWithText iconImage={reactimg} title={"ReactJS"} />
          <TechIconWithText iconImage={blender} title={"Blender 3D"} />
          <TechIconWithText iconImage={unreal} title={"Unreal Engine"} />
        </div>
        <div className='flex-auto md:w-1/2 px-10 md:px-24'>
          <h2 className="mb-8 text-3xl font-extralight text-white">
            These are just our favourite ones.<br />We are beyond these
          </h2>
          {/* <Link to={"/technology"}>
            <MyButton text={"Know More..."} />
          </Link> */}

        </div>
      </div>

      <div className='flex flex-col justify-center mx-10 md:mx-24'>
        <h2 className='text-white self-center md:self-end text-4xl md:text-6xl text-center md:text-right pt-20 w-max mb-20'>
          <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>
            Industries We Serve
          </span>
        </h2>
        <div className='w-full grid md:grid-cols-2 lg:grid-cols-3 gap-10 text-white'>
          <div className='bg-slate-800 p-10 text-center rounded-xl'>
            <img className='m-auto pb-3 w-24' src={healthcare} alt="loading" />
            <h1 className='font-bold'>Health Care</h1>
            <h4>Streamline clinic workflow, improve communication and access patient information more easily.</h4>
          </div>
          <div className='bg-slate-800 p-10 text-center rounded-xl'>
            <img className='m-auto pb-3' src={a1} alt="loading" />
            <h1> <b>Automotive</b></h1>
            <h4>Drive into the future of personalized automobile experiences.</h4>
          </div>
          {/* <div className='bg-slate-800 p-10 text-center rounded-xl'>
            <img className='m-auto pb-3' src={a2} alt="loading" />
            <h1><b>Defense</b></h1>
            <h4>Integrate defense training processes with on-the-ground digital experiences.</h4>
          </div> */}
          <div className='bg-slate-800 p-10 text-center rounded-xl' >
            <img className='m-auto pb-3' src={a3} alt="loading" />
            <h1><b>Energy</b></h1>
            <h4>Fuel the future with smart and sustainable experiences.</h4>
          </div>
          <div className='bg-slate-800 p-10 text-center rounded-xl'>
            <img className='m-auto pb-3' src={a4} alt="loading" />
            <h1><b>Retail</b></h1>
            <h4>Elevate customer experiences across all channels..</h4>
          </div>
          <div className='bg-slate-800 p-10 text-center rounded-xl'>
            <img className='m-auto pb-3' src={a5} alt="loading" />
            <h1><b>Manufacturing</b></h1>
            <h4>Accelerate productivity with the power of visual data.</h4>
          </div>
          <div className='bg-slate-800 p-10 text-center rounded-xl'>
            <img className='m-auto pb-3' src={a6} alt="loading" />
            <h1><b>Entertainment</b></h1>
            <h4>Serve hyper-personalized experiences attuned to user needs.</h4>
          </div>
        </div>
      </div>
      {/* 
      <div className='mt-32'>
        <a href="#" className="flex flex-col mx-auto my-10 ml-72 items-center bg-white border rounded-lg shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <img src={charimg} className="object-cover w-full pl-5 font-bold text-blue-400 text-4xl rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" />
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">3D Animation</h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
          </div>
        </a>



        <a href="#" className="flex flex-col mx-auto my-10 mr-72 items-center bg-white border rounded-lg shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Visual Effects</h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
          </div>
          <img src={charimg2} className="object-cover w-full pl-5 font-bold text-blue-400 text-4xl rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" />
        </a>



        <a href="#" className="flex flex-col mx-auto ml-72 items-center bg-white border rounded-lg shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <img src={charimg3} className="object-cover w-full pl-5 font-bold text-blue-400 text-4xl rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" />
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Game Development</h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
          </div>
        </a>

      </div> */}
      <div className='mt-20'>
        <img src={lastimg} alt='loading' className='h-[600px] opacity-10 bg-fixed object-center w-full bg-current' />
        <div className='-translate-y-96 relative'>
          <h2 className='text-4xl sm:text-8xl text-transparent text-center font-extrabold bg-clip-text bg-gradient-to-l from-blue-500 via-purple-600 to-pink-400'> Want to work with us? </h2>
          <h6 className='text-white text-lg sm:text-xl text-center font-thin'>Drop us a line! We are here to answer your questions 24/7</h6>
          <div className='flex justify-center'>
            <Link to="/contactus"><MyButton text="Contact us" /></Link>
          </div>
        </div>
      </div>
    </div>
  )
}
