import React from 'react'
import img from '../../assets/images/bgpic.jpg'
import bannerbg from '../../assets/images/banner.jpg'
import googleplay from '../../assets/images/googleplay.png'
import windows from '../../assets/images/windows store.png'
import cars from '../../assets/images/twincars/cars.png'
import barrier from '../../assets/images/twincars/barrier.png'
import dotSquareBlue from '../../assets/images/twincars/dot1.png'
import dotSquareRed from '../../assets/images/twincars/dot2.png'
import dotCircleRed from '../../assets/images/twincars/dot3.png'
import dotCircleBlue from '../../assets/images/twincars/dot4.png'
import PageHeader from '../common/PageHeader'
import TechForGaming from '../technology/TechForGaming'

export default function GamingPage() {
  return (
    <div>
      <PageHeader img={img}>
        Bringing Fun to Life<br />
        We are dedicated to creating the best gaming experiences
      </PageHeader>
      <div className='flex justify-center md:justify-end mx-24 mb-32 md:mb-24'>
        <h2 className='text-white text-5xl md:text-6xl text-center md:text-right pt-14 font-extrabold pb-4 w-max border-white'><span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Gaming</span> &&nbsp;Fun </h2>
      </div>
      <div className='flex flex-col px-10 md:p-24'>
        <div className='flex w-3/4 mb-8 self-center justify-between'>
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-3' src={dotCircleBlue} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-5' src={dotSquareBlue} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-7' src={dotCircleRed} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-9' src={dotSquareRed} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-12' src={dotCircleBlue} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-14' src={dotSquareBlue} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-12' src={dotCircleRed} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-5' src={dotSquareRed} alt='dot' />
        </div>
        <div className='flex'>
          <img src={cars} className='w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 rotate-45 animate-pulse' alt='cars' />
          <div className='flex-1 px-4'>
            <h1 className='flex-1 md:text-4xl hidden md:block lg:text-6xl font-extrabold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Twin Cars - Ace Of Fun</h1>

          </div>
          <img src={barrier} className='w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32' alt='barriet' />
        </div>
        <div className='pt-14 flex flex-col'>
          <h1 className='flex-1 md:hidden text-4xl lg:text-6xl font-extrabold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>
            Twin Cars - Ace Of Fun
          </h1>
          <div className='self-center text-center pt-4'>
            <h2 className='text-xl font-bold text-white'>Get it on</h2>
            <a href='https://play.google.com/store/apps/details?id=com.praleetechnology.twincars' target='_blank'> <img className='w-20 h-20 inline-block pt-3 mx-2' src={googleplay} alt='google play' />
              <span className='text-4xl text-white relative top-3'>Google Play Store</span>
            </a>
            {/* <img className='w-20 h-20 inline-block mx-2' src={windows} alt='google play' /> */}
          </div>
        </div>
      </div>

      <TechForGaming />
      <div className='bg-gradient-to-r from-blue-600 via-purple-600 to-pink-400  mt-0' style={{
        backgroundImage: `url(${bannerbg})`,
        backgroundAttachment: 'fixed'
      }}>
        <div className='bg-gray-900 bg-opacity-10 py-4 md:py-32 w-full flex justify-center'>
          <p className='text-2xl md:text-4xl lg:text-6xl text-white text-center font-extrabold rounded-3xl p-10 md:py-12 md:px-24 bg-gray-900 bg-opacity-10 backdrop-blur-2xl w-max'> we have just <br />started the journey<br />
            lots of fun games are on the way
          </p>
        </div>
      </div>

    </div>
  )
}
