import React from 'react'
import img from '../../assets/images/bgpic.jpg'
import img2 from '../../assets/images/webdevelopment.jpg'




export default function PortfolioPage() {
  return (
    <div>
      <div className='relative '>
        <div className='absolute  top-24 ml-12 pl-12'>
          <h1 className=' text-white text-4xl  '>
            <b> GET INSPIRED BY<br/>
            OUR FINEST WORK. </b>
          </h1>
        </div>
        <div className='h-72 opacity-25 w-full' style={{ backgroundImage: `url(${img})`,alt: "loading", backgroundPosition: 'center', backgroundSize: '100%' }}>
        </div>
      </div>
      
                <div> 
                <h1 className='text-sky-400 font-bold  text-3xl text-center mt-10 tracking-widest'>Portfolio</h1>
                  <h1 className='text-white font-bold  text-6xl text-center mt-8  ' > A Glimpse Of <br/> 
              Our <span className='text-orange-400'>Finest </span> Creations</h1> 
            </div>
    

     
   

<div className='flex'>
<div className="w-48 py-20 px-20 rounded  mx-14 my-14 flex-auto shadow-lg shadow-slate-700">
        <img className='w-96' src={img2} alt="" />
        <div className="px-2 py-4">
          <div className="font-bold text-white  text-xl mb-2">The Coldest Sunset</div>
          <p className="text-gray-500 text-base">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a>Preview</a></span>
         </div>
      </div>

      <div className="w-48 py-20 px-20  rounded mx-14 my-14 flex-auto shadow-lg shadow-slate-700">
        <img className='w-96' src={img2} alt="" />
        <div className="px-2 py-4">
          <div className="font-bold text-white text-xl mb-2">The Coldest Sunset</div>
          <p className="text-gray-500 text-base">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a>Preview</a></span>
         </div>
      </div>


</div>


<div className='flex'>
<div className="w-48 py-20 px-20  rounded mx-14 my-14 flex-auto shadow-lg shadow-slate-700">
        <img className='w-96' src={img2} alt="" />
        <div className="px-2 py-4">
          <div className="font-bold text-white text-xl mb-2">The Coldest Sunset</div>
          <p className="text-gray-500 text-base">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a>Preview</a></span>
         </div>
      </div>

      <div className="w-48 py-20 px-20  rounded mx-14 my-14 flex-auto shadow-lg shadow-slate-700">
        <img className='w-96' src={img2} alt="" />
        <div className="px-2 py-4">
          <div className="font-bold text-white text-xl mb-2">The Coldest Sunset</div>
          <p className="text-gray-500 text-base">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a>Preview</a></span>
         </div>
      </div>


</div>

<h1 className='text-white font-bold  text-6xl text-center mt-16' >Upcoming  <span className='text-blue-500'>Projects</span></h1>

<div className='flex px-40 py-28'>
   <div className='flex-auto m-5'>
   <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
      </a>
   </div>
   <div className='flex-auto m-5'>
   <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
      </a>
   </div>
   <div className='flex-auto m-5'>
   <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
      </a>
   </div>

</div>

<div className='h-96 bg-gradient-to-r from-blue-700 via-blue-500 to-sky-300 '>
        <h1 className='text-white text-4xl text-center pt-10'>No matter your industry, we can help you <br/> achieve your cloud and data goals</h1>
             <h1 className='text-white text-xl text-center pt-10 pb-10'>Being an industry leader means not settling for the status quo, and we  <br/>
             can help you redefine what that means for your business</h1>
             <span className='text-white ml-[600px]'><button className='border-2 rounded-full p-3 hover:bg-gray-700'>Explore Services</button> <button className='border-2 rounded-full p-3 hover:bg-gray-700'>get in touch</button></span>
        </div>


    </div>
  )
}
