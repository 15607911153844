import React from 'react'
import ankitphoto2 from '../../assets/images/ankitoriginal.jpg'
import img from '../../assets/images/bgpic2.jpg'
import PageHeader from '../common/PageHeader'
import CreditPersonSection from './CreditPersonSection'

const Credits = () => {
  return (
    <div>
      <PageHeader img={img} >
        We recognize and celebrate<br /> the contributions of our team members, and reward their hard work and dedication
      </PageHeader>
      <div className='flex justify-center md:justify-end mx-24 mb-16 md:mb-24'>
        <h2 className='text-white text-5xl md:text-6xl text-center md:text-right pt-14 font-extrabold w-max border-white'><span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Credits</span> </h2>
      </div>

      <CreditPersonSection name="Vaishnavi Sapkale" designation="Full Stack Intern At Pralee" isImageInRight={true}>
        <p className='text-gray-400 text-xl font-thin'>
          Vaishnavi has made a significant impact on our company in a
          short amount of time. <span className='text-white'>She developed our official website very well </span>
          and has been instrumental in the development of several of our
          most successful projects, and her attention to detail and commitment
          to excellence has helped to set a new standard for our development team.
        </p>
      </CreditPersonSection>
      <CreditPersonSection img={ankitphoto2} name="Ankit Bourasi" designation="Full Stack & Unity Development Intern At Pralee">
        <p className='text-gray-400 text-xl font-thin'>
          We are proud to recognize Ankit for his outstanding contributions to our company.
          He has been a dedicated and valued member of our development team.
          He is the <span className='text-white'> solo developer for the game Twin Cars - Ace Of Fun</span>. His passion and commitment to delivering high-quality software and games is truly inspiring.
        </p>
      </CreditPersonSection>
      <div className='mt-36 ' style={{ backgroundImage: `url(${img})`, backgroundSize: '100% 100%' }}>
        <div className='w-full py-32 h-full flex justify-center bg-gray-900 bg-opacity-80'>
          <div className='self-center'>
            <p className='text-5xl sm:text-7xl text-transparent text-center font-extrabold bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'> Thank You</p>
            <p className='mt-2 text-white text-xl text-center font-thin'>for visiting</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Credits;