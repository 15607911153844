import React from 'react'

export default function OurTeamIsOurPower({ isLeft, children }) {
    return (
        <div className={`w-full flex flex-col md:flex-row items-center ${isLeft ? 'justify-start' : 'justify-end'} lg:px-28 xl:px-36 mt-10`}>
            <div className={` md:w-3/5 border-gray-700 bg-gray-800 hover:bg-gray-700 border rounded-lg shadow-lg`}>
                <div className="p-4">
                    <p className="text-white text-xl">
                        {children}
                    </p>
                </div>
            </div>
        </div>

    )
}