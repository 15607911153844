import React from 'react'

export default function PageHeader({img, children}) {
  return (
    <div className='h-72 flex w-full' style={{
        backgroundImage: `url(${img})`,
        alt: "loading",
        backgroundPosition: 'center',
        backgroundSize: '100%',
        // backgroundAttachment: 'fixed'
      }}>
        <div className='px-10 md:px-24 flex items-center md:justify-start justify-center w-full bg-gray-900 bg-opacity-80'>
          <h1 className=' text-white text-2xl md:text-4xl mt-6 text-center md:text-left'>
            {children}
          </h1>
        </div>
      </div>
  )
}
