import React from 'react'

export default function ServiceSection({ img, title, isLeftTitle, children }) {
    return (
        isLeftTitle ?
            <div className='min-h-max'>
                <div class="flex flex-col md:flex-row items-center md:justify-end min-h-[400px]">
                    <img class="h-max self-end opacity-50 md:w-2/3 w-full" src={img} alt="loading" />
                    <div class="absolute -translate-y-32 md:translate-y-0 md:left-40 backdrop-blur-xl md:backdrop-blur-2xl h-max px-10 md:px-20 py-20 md:w-1/2 w-5/6">
                        <h1 class="text-4xl md:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-l from-blue-300 via-purple-600 to-pink-600 h-max pb-4">{title}</h1>
                        <p class="text-gray-300 ">
                            {children}
                        </p>

                    </div>
                </div>
            </div>
            : 
            <div className='min-h-max'>
                <div class="flex flex-col md:flex-row items-center md:justify-start min-h-[400px]">
                    <img class="h-max self-start opacity-50 md:w-2/3 w-full" src={img} alt="loading" />
                    <div class="absolute -translate-y-32 md:translate-y-0 md:right-40 backdrop-blur-xl md:backdrop-blur-2xl h-max px-10 md:px-20 py-20 md:w-1/2 w-5/6">
                        <h1 class="text-4xl md:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-300 via-purple-600 to-pink-600 h-max pb-4">{title}</h1>
                        <p class="text-gray-300 ">
                            {children}
                        </p>

                    </div>
                </div>
            </div>
    )
}
