import React from 'react'

export default function ReachOutLinks({ img, title, link, linkTitle, newTab }) {
    var targetstr = newTab ? '_blank' : "_self";
    return (
        <>
            <div className='flex items-center p-2 mt-5'>
                <div className='w-6 h-6'><img src={img} alt='loading' /></div>
                <h1 className=' pl-4 text-white text-xl'>{title}</h1>
            </div>
            <a className='text-white pb-3 pl-3 hover:text-zinc-500' target={targetstr} href={link} rel="noreferrer">{linkTitle}</a>
        </>
    )
}
