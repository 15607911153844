import React, { useRef, useState } from 'react'
import img from '../../assets/images/bgpic.jpg'
import gmail from '../../assets/images/gmail.png'
import call from '../../assets/images/telephone.png'
import MyButton from '../common/MyButton'
import PageHeader from '../common/PageHeader'
import linkedinimg from '../../assets/images/linkedin.png'
import youtube from '../../assets/images/youtube.png'
import github from '../../assets/images/github.png'
import ReachOutLinks from './ReachOutLinks'
import emailjs from '@emailjs/browser';

export default function ContactPage() {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs.sendForm("service_pareufw", "template_oy51g7t", form.current, "QEwMRsvMTdpVB51eO").then((result) => {
      if (result) {
        form.current.reset()
        setMessageSent(true)
      } else {
        alert("something went wrong try again later")
      }
      setLoading(false)
    });
  };
  return (
    <div className='pb-24'>
      <PageHeader img={img}>
        We would love to hear from you<br /> Whether you have a question or suggestion
      </PageHeader>
      <div className='flex justify-center md:justify-end mx-24 mb-14 md:mb-24'>
        <h2 className='text-white text-5xl md:text-6xl text-center md:text-right pt-14 font-extrabold pb-4 w-max'>Contact<span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'> Us</span> </h2>
      </div>


      {/* <h1 className='md:text-5xl text-4xl text-center font-bold  md:text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 text-bold mt-20 md:mt-32'>Contact Us</h1> */}
      {/* <p className='p-10 pb-16 text-center text-zinc-400'>DO you have a project you would like us to work on? Or perhaps a few questions?  <br />
          Contact us and we will be more than happy to assist you</p> */}

      <div className='flex flex-col md:flex-row px-10 lg:px-24 gap-20'>
        <div className='py-24 px-10 rounded-2xl flex-auto shadow-sm shadow-purple-700 hover:shadow-sky-600 transition-all duration-300'>
          <h2 className='text-transparent bg-clip-text bg-gradient-to-l from-blue-500 via-purple-600 to-pink-400 text-4xl md:text-5xl font-extrabold w-max'>Get in Touch</h2>
          <p className='font-extralight mb-12 text-gray-100 mt-2'>
            Our team will respond to your enquiry as soon as possible.</p>


          <form ref={form} onSubmit={sendEmail}>

            <div className="mb-6 mt-5">
              <label htmlFor="email" className="block mb-2 font-extralight text-xl text-white">Your Email</label>
              <input name='contact_email' type="email" id="email" className="w-full px-3 py-4 bg-transparent shadow-inner transition-all duration-300 shadow-purple-900 focus:shadow-purple-500 outline-none rounded-lg text-xl focus:rounded-xl text-white font-extralight" placeholder="Enter Your Email Address" required />
            </div>
            <div className="mb-6">
              <label htmlFor="name" className="block mb-2 font-extralight text-xl text-white">Your Name</label>
              <input name='contact_name' type="text" id="name" className="w-full px-3 py-4 bg-transparent shadow-inner transition-all duration-300 shadow-purple-900 focus:shadow-purple-500 outline-none rounded-lg text-xl focus:rounded-xl text-white font-extralight" placeholder='Enter Your Name' required />
            </div>
            <div className='mb-6'>
              <label htmlFor="message" className="block mb-2 font-extralight text-xl text-white">Your message</label>
              <textarea name='contact_message' id="message" rows={4} className="w-full px-3 py-4 bg-transparent shadow-inner transition-all duration-300 shadow-purple-900 focus:shadow-purple-500 outline-none rounded-lg text-xl focus:rounded-xl text-white font-extralight" placeholder="Leave A Comment..." defaultValue={""} />
            </div>
            {!messageSent && !loading && <MyButton text={"Send Message"} />}
            {loading && <p className='text-lg text-purple-500'>Wait...</p>}
          </form>
          {messageSent && <p className='text-lg text-purple-500'>thank you for contacting us, we will reach you soon.</p>}
        </div>
        <div className='flex-auto'>
          <div className='md:pt-24'>
            <h1 className='text-3xl bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400 bg-clip-text text-transparent font-bold pb-5 '>Reach Out to Us</h1>
            <div className='h-1 w-16 bg-purple-500' />
            <div className='mt-5'>
              <ReachOutLinks title="Email" img={gmail} link="mailto:info@praleetechnology" linkTitle="info@praleetechnology.com" />
              {/* <ReachOutLinks title="Contact" img={call} link="tel:+917333563069" linkTitle="+91 733 356 3069" /> */}
              <ReachOutLinks title="LinkedIn" img={linkedinimg} link="https://www.linkedin.com/company/praleetechnology" linkTitle="/company/praleetechnology" newTab={true} />
              <ReachOutLinks title="Youtube" img={youtube} link="https://www.youtube.com/@praleetechnology" linkTitle="/@praleetechnology" newTab={true} />
              <ReachOutLinks title="GitHub" img={github} link="https://github.com/praleetechnology" linkTitle="/praleetechnology" newTab={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
