import React from 'react'

export default function IconExperties({ iconImage, title }) {
    return (
        <div className='bg-gray-900 rounded-3xl flex justify-center items-center p-5 opacity-90 text-white text-center '>
            <div className='flex flex-row md:flex-col items-center justify-center h-full w-max text-white text-center'>
                <img className='h-32 w-32 p-4' src={iconImage} alt="loading" />
                <h6 className='text-center text-2xl font-bold'>{title}</h6>
            </div>
        </div>
    )
}
