import { Outlet, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useEffect } from "react";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div className="bg-gray-900 min-h-screen w-full">
        <Header />
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default App;