import React from 'react'


export default function OurValuesCard({ image, title, children }) {
    return (
        <div className='flex flex-col flex-auto justify-start items-center w-full h-auto py-20 px-10 bg-gray-800 rounded-2xl shadow-sm transition-all duration-500 hover:shadow-sky-400 shadow-purple-700 '>
            <img src={image} alt='Loading' />
            <h1 className='text-blue-400 font-thin text-4xl text-center pt-3'>{title}</h1>
            <p className='text-center text-gray-300 pt-3 text-lg'> {children}</p>
        </div>
    )
}
